const moment = require('moment'); // You'll need a library like 'moment' for date calculations

const formatNumber = (stringWithCommas)=>{
    const numberWithoutCommas = parseFloat(stringWithCommas.replace(/,/g, ''), 10);
    return numberWithoutCommas
}
const formatDate = (dateString) =>{
    const parsedDate = moment(dateString, "DD-MMM-YYYY");
    return parsedDate.format("YYYY-MM-DD");
}
const formatPaymentDate = (dateString) =>{
    const parsedDate = moment(dateString, "DD MMM YY");
    return parsedDate.format("YYYY-MM-DD");
}
const numberWithCommas = (number)=>{
    const newNumber = Math.floor(number)
    const numberString = new Intl.NumberFormat().format(newNumber)
    return (numberString !== "0" ? numberString : "-")
}
const dateAsString = (date) =>{
    const parsedDate = moment(date, "YYYY-MM-DD")
    return parsedDate.format("DD-MMM-YYYY");
}
const createLoanStatementA = (loanData, paymentData, startDate, duration, 
    interestRate, loanAWSstatus, lateInterestBySystem, penaltiesData, 
    interestRateFrequency, loanDurationFormat, dateAsObject, grossLoanValue, 
    paymentFrequency
    ) => {
        
    const loansArray = loanData.map(item => ({
        id: item.id,
        date: formatDate(item.date), 
        description: "Instalment Due",
        instalmentDue: formatNumber(item.totalPayment),
        paymentAmount: 0
    }))

    const latePaymentInterestInterval = ()=>{
        switch(interestRateFrequency){
            case 'per day':
                return 'days';
                break;
            case 'per week':
                return 'weeks';
                break;
            case 'per month':
                return 'months';
                break;
            case 'per year':
                return 'years';
                break;
            default: 
                return 'months';
        }
    }

    const intimePenalties = []
    penaltiesData.forEach(item => {
        const endDate = moment(startDate).add(duration, loanDurationFormat);
        const penaltyDate = moment(item.penaltyDate)
        if(penaltyDate.isSameOrBefore(endDate)){
            intimePenalties.push({
                id: item.id,
                date: formatPaymentDate(item.penaltyDate), 
                description: item.comment,
                instalmentDue: item.penaltyAmount,
                paymentAmount: 0
            })
        }
    })
    
    const intimePayments = []
    paymentData.forEach(item =>{
        const endDate = moment(startDate).add(duration, loanDurationFormat);
        const paymentDate = moment(item.paymentDate)
        if(paymentDate.isSameOrBefore(endDate)){
            intimePayments.push({
                id: item.id,
                date: formatPaymentDate(item.paymentDate),
                description: "Payment Made",
                instalmentDue: 0,
                paymentAmount: item.paymentAmount 
            })
        }
    })

    const latePayments = []
    paymentData.forEach(item =>{
        const endDate = moment(startDate).add(duration, loanDurationFormat);
        const paymentDate = moment(item.paymentDate)
        if(!paymentDate.isSameOrBefore(endDate)){
            latePayments.push({
                id: item.id,
                date: formatPaymentDate(item.paymentDate),
                description: "Payment Made",
                paymentAmount: item.paymentAmount 
            })
        }
    })

    const latePenalties = []
    penaltiesData.forEach(item =>{
        const endDate = moment(startDate).add(duration, loanDurationFormat);
        const penaltyDate = moment(item.penaltyDate)
        if(!penaltyDate.isSameOrBefore(endDate)){
            latePenalties.push({
                id: item.id,
                date: formatPaymentDate(item.penaltyDate), 
                description: item.comment,
                instalmentDue: item.penaltyAmount,
                paymentAmount: 0
            })
        }
    })

    const paymentsArray = paymentData.map(item =>({
        id: item.id,
        date: formatPaymentDate(item.paymentDate),
        description: "Payment Made",
        instalmentDue: 0,
        paymentAmount: item.paymentAmount 
    }))
     //Get total of payments
    const totalPaymentsAsNumber = paymentsArray.reduce((acc, obj) => acc + obj.paymentAmount, 0)
    const totalPayments = numberWithCommas(totalPaymentsAsNumber)
    //Combine the lonas and payments arrays by date
    const allTransactions = loansArray.concat(intimePayments).concat(intimePenalties)
    .sort((a, b) => moment(a.date).diff(moment(b.date)));

    //Create an additional attribute of Loan Balance
    const loanStatement = []
    const firstEntry = {
        id: allTransactions[0].id,
        date: allTransactions[0].date,
        description: allTransactions[0].description,
        instalmentDue: allTransactions[0].instalmentDue,
        paymentAmount: allTransactions[0].paymentAmount
    }
    firstEntry.balance = grossLoanValue
    if(firstEntry.description.includes("PENALTY")){
        firstEntry.balance += firstEntry.instalmentDue
    }
    if(firstEntry.description.includes("Payment")){
        firstEntry.balance -= firstEntry.paymentAmount
    }
    loanStatement.push(firstEntry)
    
    for(let i=1; i<allTransactions.length ; i++){
        const entry = {
            id: allTransactions[i].id,
            date: allTransactions[i].date,
            description: allTransactions[i].description,
            instalmentDue: allTransactions[i].instalmentDue,
            paymentAmount: allTransactions[i].paymentAmount
        }
        entry.balance = loanStatement[i-1].balance - entry.paymentAmount
        if(entry.description.includes("PENALTY")){
            entry.balance += entry.instalmentDue
        }
        loanStatement.push(entry)
    }
    //Check if loan is current
    const today = moment();
    const endDate = moment(startDate).add(duration, loanDurationFormat);
    const loanStillCurrent = endDate.isAfter(today)
    let loanStatus = loanAWSstatus
    if(!loanAWSstatus.includes('override')){
        loanStatus = loanStillCurrent ? 'current' : 'overdue'; 
    }
    //add monthly interst if loan is not current. 
    if(!loanStillCurrent && (loanStatement[loanStatement.length - 1].balance >0)){
        //create dates for monthly interest
        const additionalEntries = []
        let checkDate = endDate.add(1, latePaymentInterestInterval());
        for(let i=1; checkDate.isBefore(today); i++){
            const entry = {
                id: 1007* Math.random(),
                date: moment(checkDate),
                description: `Late Payment Interest at ${interestRate}% ${interestRateFrequency}`,
                paymentAmount: 0
            }
            lateInterestBySystem && additionalEntries.push(entry)
            checkDate = moment(entry.date).add(1, latePaymentInterestInterval())
        }
        const allAdditions = additionalEntries.concat(latePayments).concat(latePenalties)
            .sort((a, b) => moment(a.date).diff(moment(b.date)));
        for(let i=0; i<allAdditions.length ; i++){
            const entry = {
                id: allAdditions[i].id,
                date: allAdditions[i].date,
                description: allAdditions[i].description,
                instalmentDue: allAdditions[i].description.includes("PENALTY") ?
                    allAdditions[i].instalmentDue
                    : allAdditions[i].paymentAmount >0 ? 0
                    : loanStatement[loanStatement.length - 1].balance * interestRate/100,
                paymentAmount: allAdditions[i].paymentAmount
            }
            entry.balance = loanStatement[loanStatement.length - 1].balance + entry.instalmentDue - entry.paymentAmount
            loanStatement.push(entry)
        }
    }
    //remove negative system penalties
    const nonNegativeStatement = loanStatement.filter((item) => {
        if(item.description.includes("Late Payment Interest at")){
          return item.balance >= 0
        }else return true
      })

    //put commas and other formatting
    const finalStatement = nonNegativeStatement.map((item) => ({
        id: item.id, 
        date: dateAsObject ? new Date(item.date) : dateAsString(item.date),
        description: item.description,
        instalmentDue: numberWithCommas(item.instalmentDue),
        paymentAmount: numberWithCommas(item.paymentAmount),
        balance: numberWithCommas(item.balance)
    }))
    //clear paid loans
    if(nonNegativeStatement[nonNegativeStatement.length - 1].balance <= 0.1){
        loanStatus = 'cleared'
    }

    let amountDueToDate
    if(loanStatus.includes('current')){
        const paymentInterval = ()=> {
            switch (paymentFrequency.toLowerCase()) {
                case 'per day':
                    return 'days';
                case 'per week':
                    return 'weeks';
                case 'per month':
                    return 'months';
                case 'per year':
                    return 'years';
                default:
                    return 'months'; 
            }
        }
        const periods = Math.max(today.diff(startDate, paymentInterval()), 1) 
        amountDueToDate = (periods * formatNumber(loanData[0].totalPayment)) - totalPaymentsAsNumber
        if((amountDueToDate > 0) && (today.diff(startDate, paymentInterval()) > 0)){
            loanStatus = 'payment due'
        }
    }else{
        amountDueToDate = nonNegativeStatement[nonNegativeStatement.length - 1].balance
    }
    return [finalStatement, totalPayments, loanStatus, amountDueToDate]
}

const createLoanStatementA2 = (loanData, paymentData, startDate, duration, 
    interestRate, loanAWSstatus, lateInterestBySystem, penaltiesData) => {

    const loansArray = loanData.map(item => ({
        id: item.id,
        date: formatDate(item.date), 
        description: "Instalment Due",
        instalmentDue: formatNumber(item.totalPayment),
        paymentAmount: 0
    }))

    const intimePenalties = []
    penaltiesData.forEach(item => {
        const endDate = moment(startDate).add(duration, 'months');
        const penaltyDate = moment(item.penaltyDate)
        if(penaltyDate.isSameOrBefore(endDate)){
            intimePenalties.push({
                id: item.id,
                date: formatPaymentDate(item.penaltyDate), 
                description: item.comment,
                instalmentDue: item.penaltyAmount,
                paymentAmount: 0
            })
        }
    })
    
    const intimePayments = []
    paymentData.forEach(item =>{
        const endDate = moment(startDate).add(duration, 'months');
        const paymentDate = moment(item.paymentDate)
        if(paymentDate.isSameOrBefore(endDate)){
            intimePayments.push({
                id: item.id,
                date: formatPaymentDate(item.paymentDate),
                description: "Payment Made",
                instalmentDue: 0,
                paymentAmount: item.paymentAmount 
            })
        }
    })

    const latePayments = []
    paymentData.forEach(item =>{
        const endDate = moment(startDate).add(duration, 'months');
        const paymentDate = moment(item.paymentDate)
        if(!paymentDate.isSameOrBefore(endDate)){
            latePayments.push({
                id: item.id,
                date: formatPaymentDate(item.paymentDate),
                description: "Payment Made",
                paymentAmount: item.paymentAmount 
            })
        }
    })

    const latePenalties = []
    penaltiesData.forEach(item =>{
        const endDate = moment(startDate).add(duration, 'months');
        const penaltyDate = moment(item.penaltyDate)
        if(!penaltyDate.isSameOrBefore(endDate)){
            latePenalties.push({
                id: item.id,
                date: formatPaymentDate(item.penaltyDate), 
                description: item.comment,
                instalmentDue: item.penaltyAmount,
                paymentAmount: 0
            })
        }
    })
    
    const paymentsArray = paymentData.map(item =>({
        id: item.id,
        date: formatPaymentDate(item.paymentDate),
        description: "Payment Made",
        instalmentDue: 0,
        paymentAmount: item.paymentAmount 
    }))
     //Get total of payments
    const totalPaymentsAsNumber = paymentsArray.reduce((acc, obj) => acc + obj.paymentAmount, 0)
    const totalPayments = numberWithCommas(totalPaymentsAsNumber)
    //Combine the lonas and payments arrays by date
    const allTransactions = loansArray.concat(intimePayments).concat(intimePenalties)
        .sort((a, b) => moment(a.date).diff(moment(b.date)));
    //Create an additional attribute of Loan Balance
    const loanStatement = []
    const firstEntry = {
        id: allTransactions[0].id,
        date: allTransactions[0].date,
        description: allTransactions[0].description,
        instalmentDue: allTransactions[0].instalmentDue,
        paymentAmount: allTransactions[0].paymentAmount
    }
    firstEntry.balance = (loansArray[0].instalmentDue * duration) - firstEntry.paymentAmount
    loanStatement.push(firstEntry)
    
    for(let i=1; i<allTransactions.length ; i++){
        const entry = {
            id: allTransactions[i].id,
            date: allTransactions[i].date,
            description: allTransactions[i].description,
            instalmentDue: allTransactions[i].instalmentDue,
            paymentAmount: allTransactions[i].paymentAmount
        }
        entry.balance = loanStatement[i-1].balance - entry.paymentAmount
        if(entry.description.includes("PENALTY")){
            entry.balance += entry.instalmentDue
        }
        loanStatement.push(entry)
    }
    //Check if loan is current
    const today = moment();
    const endDate = moment(startDate).add(duration, 'months');
    const loanStillCurrent = endDate.isAfter(today)
    let loanStatus = loanAWSstatus
    if(!loanAWSstatus.includes('override')){
        loanStatus = loanStillCurrent ? 'current' : 'overdue'; 
    }
    //add monthly interst if loan is not current. 
    if(!loanStillCurrent && (loanStatement[loanStatement.length - 1].balance >0)){
        //create dates for monthly interest
        const additionalEntries = []
        let checkDate = endDate.add(1, 'month');
        for(let i=1; checkDate.isBefore(today); i++){
            const entry = {
                id: 1007* Math.random(),
                date: moment(checkDate),
                description: `Late Payment Interest at ${interestRate}% per month`,
                paymentAmount: 0
            }
            lateInterestBySystem && additionalEntries.push(entry)
            checkDate = moment(entry.date).add(1, 'months')
        }
        const allAdditions = additionalEntries.concat(latePayments).concat(latePenalties)
            .sort((a, b) => moment(a.date).diff(moment(b.date)));
        for(let i=0; i<allAdditions.length ; i++){
            const entry = {
                id: allAdditions[i].id,
                date: allAdditions[i].date,
                description: allAdditions[i].description,
                instalmentDue: allAdditions[i].description.includes("PENALTY") ?
                    allAdditions[i].instalmentDue
                    : allAdditions[i].paymentAmount >0 ? 0
                    : loanStatement[loanStatement.length - 1].balance * interestRate/100,
                paymentAmount: allAdditions[i].paymentAmount
            }
            entry.balance = loanStatement[loanStatement.length - 1].balance + entry.instalmentDue - entry.paymentAmount
            loanStatement.push(entry)
        }
    }
    
    //remove negative system penalties
    const nonNegativeStatement = loanStatement.filter((item) => {
        if(item.description.includes("Late Payment Interest at")){
          return item.balance >= 0
        }else return true
      })
    //put commas and other formatting
    const finalStatement = nonNegativeStatement.map((item) => ({
        id: item.id, 
        date: new Date(item.date),
        description: item.description,
        instalmentDue: numberWithCommas(item.instalmentDue),
        paymentAmount: numberWithCommas(item.paymentAmount),
        balance: numberWithCommas(item.balance)
    }))
    //clear paid loans
    if(nonNegativeStatement[nonNegativeStatement.length - 1].balance <= 0){
        loanStatus = 'cleared'
    }

    let amountDueToDate
    if(loanStatus.includes('current')){
        const periods = Math.max(today.diff(startDate, 'months'), 1) 
        amountDueToDate = (periods * formatNumber(loanData[0].totalPayment)) - totalPaymentsAsNumber
        if((amountDueToDate > 0) && (today.diff(startDate, 'months') > 0)){
            loanStatus = 'payment due'
        }
    }else{
        amountDueToDate = nonNegativeStatement[nonNegativeStatement.length - 1].balance
    }
    return [finalStatement, totalPayments, loanStatus, amountDueToDate]
}
export {createLoanStatementA, numberWithCommas, formatDate, createLoanStatementA2}
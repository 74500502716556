import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { Formik, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import React, { useState, useContext } from "react";
import UploadFile from "../../components/UploadFile";
import { UserIDContext } from "../../App";
import { uploads3 } from "../../assets/uploads3";
import { API, graphqlOperation } from "aws-amplify";
import { createBorrower } from "../../graphql/mutations";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { tokens } from "../../theme";
import { useEffect } from "react";
import PointsManager from "../../components/PointsManager";
import { replaceSlashes } from "../../assets/replaceSlahes";

const ViewAndEditBorrower = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [nationalIDPic, setNationalIDPic] = useState()
  const [nationalIDPicCloudinary, setNationalIDPicCloudinary] = useState()
  const [passportPic, setPassportPic] = useState()
  const [passportPicCloudinary, setPassportPicCloudinary] = useState()
  const [processing, setProcessing] = useState('Update Borrower Details')
  const [borrower, setBorrower] = useState()
  const [updatedBorrower, setUpdatedBorrower] = useState()
  const [borrowerDeleted, setBorrowerDeleted] = useState()
  const {id, firstName, otherName, phoneNumber, otherPhoneNumber} = useParams()
  const sliced = string => string.slice(1)
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(()=>{
    const getBorrowerDetails = async()=>{
        try{
            const borrowerDetails = await API.graphql(graphqlOperation(`query MyQuery {
                getBorrower(id: "${sliced(id)}") {
                  address
                  age
                  email
                  employerName
                  gender
                  nationalIdNumber
                  nationalIdPicture
                  otherPhoneNumber
                  passportPicture
                }
              }
            `));
            if(borrowerDetails.data.getBorrower){
              setBorrower(borrowerDetails.data.getBorrower)
              setNationalIDPic(borrowerDetails.data.getBorrower.nationalIdPicture)
              setPassportPic(borrowerDetails.data.getBorrower.passportPicture)
            }
        }catch(e){
            console.log('Error geting borrower details: ',e)
        }
    }
    getBorrowerDetails()
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    const saveUpdatedBorrower = async() =>{
      try{
          const updatedBorrowerDetails = await API.graphql(graphqlOperation(`mutation MyMutation {
            updateBorrower(input: {
              id: "${sliced(id)}", 
              address: "${updatedBorrower.address}", 
              age: "${updatedBorrower.age}", 
              email: "${updatedBorrower.email}", 
              employerName: "${updatedBorrower.employerName}",
              firstname: "${updatedBorrower.firstName}",
              gender: "${updatedBorrower.gender}",
              nationalIdNumber: "${updatedBorrower.nationalIDNumber}",
              nationalIdPicture: "${nationalIDPicCloudinary}",
              otherPhoneNumber: "${updatedBorrower.otherPhoneNumber}",
              othername: "${updatedBorrower.otherName}",
              passportPicture: "${passportPicCloudinary}",
              phoneNumber: "${updatedBorrower.phoneNumber}"
            }) {
              firstname
              othername
            }
          }`));
          if(updatedBorrowerDetails){
            // console.log('updatedBorrowerDetails::: ', updatedBorrowerDetails);
            const borrowerName = updatedBorrowerDetails.data.updateBorrower.firstname+" "+
              updatedBorrowerDetails.data.updateBorrower.othername
            setTimeout(()=>goToBorrowers(borrowerName), 1500)
          }
      }catch(e){
          console.log('Error updating borrower: ',e)
      }
    }
    if(passportPicCloudinary && nationalIDPicCloudinary){
      saveUpdatedBorrower();
    }
  }, [nationalIDPicCloudinary, passportPicCloudinary])


  const deleteBorrower = async()=>{
    try{
      const deletedBorrower = await API.graphql(graphqlOperation(`mutation MyMutation {
        deleteBorrower(input: {id: "${sliced(id)}"}) {
          id
        }
      }`));
      if(deletedBorrower){
        setBorrowerDeleted(deletedBorrower)
        setTimeout(()=>navigate(`/manageBorrowers`), 1500)
      }
    }catch(e){
      console.log('Error deleting borrower: ',e)
    }
  }

  const CssTextField = styled(TextField, {
    shouldForwardProp: (props) => props !== "focusColor"
  })((p) => ({
    // input label when focused
    "& label.Mui-focused": {
      color: p.focusColor
    }
  }));
  const labelFocusColor = '#3da58a'

  const initialValues = {
    firstName: sliced(firstName),
    otherName: sliced(otherName),
    email: borrower ? borrower.email : '',
    phoneNumber: sliced(phoneNumber),
    otherPhoneNumber: otherPhoneNumber ? sliced(otherPhoneNumber) : "",
    gender: borrower ? borrower.gender : '', 
    age: borrower ? borrower.age : '',
    nationalIDNumber: borrower ? borrower.nationalIdNumber : '',
    address: borrower ? borrower.address : '',
    employerName: borrower ? borrower.employerName : '',
  };

  const handleFormSubmit = (values) => {
    setProcessing('Processing...Please Wait...')
    setUpdatedBorrower(values)
    uploads3(nationalIDPic, setNationalIDPicCloudinary)
    uploads3(passportPic, setPassportPicCloudinary)
  };

  const goToBorrowers =(borrowerName) =>{
    borrowerName = replaceSlashes(borrowerName)
    navigate(`/manageBorrowers/:${borrowerName}`)
  }
  
  return (
    <Box>
      <PointsManager />
      {!borrower && <Box m="40px"><h1>LOADING...</h1></Box>}
      {borrowerDeleted && <Box m="60px">
        <Header title={'BORROWER SUCCESSFULLY DELETED'} />
      </Box>}
      {!borrowerDeleted && <Box>
        {borrower && <Box m="20px" maxWidth="700px" >
          <Header title={`${sliced(firstName)} ${sliced(otherName)} Profile`} subtitle="Change/Update the Details below" />
          <Formik
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={checkoutSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  }}
                >
                  <CssTextField focusColor={labelFocusColor}
                    fullWidth
                    placeholder='Place holder'
                    variant="filled"
                    type="text"
                    label="First Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.firstName}
                    name="firstName"
                    error={!!touched.firstName && !!errors.firstName}
                    helperText={touched.firstName && errors.firstName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <CssTextField focusColor={labelFocusColor}
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Other Names"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.otherName}
                    name="otherName"
                    error={!!touched.otherName && !!errors.otherName}
                    helperText={touched.otherName && errors.otherName}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <CssTextField focusColor={labelFocusColor}
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Phone Number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phoneNumber}
                    name="phoneNumber"
                    error={!!touched.phoneNumber && !!errors.phoneNumber}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <Box sx={{gridColumn: 'span 4'}}>
                      <Typography color={colors.greenAccent[400]} >Only the Fields Above are Compulsory. The fields below are all Optional.</Typography>
                  </Box>
                  <CssTextField focusColor={labelFocusColor}
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Other Phone Number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.otherPhoneNumber}
                    name="otherPhoneNumber"
                    // error={!!touched.otherPhoneNumber && !!errors.otherPhoneNumber}
                    // helperText={touched.otherPhoneNumber && errors.otherPhoneNumber}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <CssTextField focusColor={labelFocusColor}
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <Box display='flex' gap="20px">
                    <p>Gender</p>
                    <Field
                      as="select"
                      label="Gender"
                      name="gender"
                      onChange={handleChange}
                    >
                      <option value="female">Female</option>
                      <option value="male">Male</option>
                    </Field>
                  </Box>
                  <CssTextField focusColor={labelFocusColor}
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Age"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.age}
                    name="age"
                    // error={!!touched.age && !!errors.age}
                    // helperText={touched.age && errors.age}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <CssTextField focusColor={labelFocusColor}
                    fullWidth
                    variant="filled"
                    type="text"
                    label="National Id Number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.nationalIDNumber}
                    name="nationalIDNumber"
                    // error={!!touched.nationalIDNumber && !!errors.nationalIDNumber}
                    // helperText={touched.nationalIDNumber && errors.nationalIDNumber}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <Box display='flex' justifyContent='space-between' gap='40px'>
                    <UploadFile
                      uploadLabel={'Upload Picture of National ID'}
                      setUploadFile={setNationalIDPic}
                      defaultImage={borrower ? borrower.nationalIdPicture : null}
                      />
                    <UploadFile
                      uploadLabel={'Upload Passport Picture'}
                      setUploadFile={setPassportPic}
                      defaultImage={borrower ? borrower.passportPicture : null}
                    />
                  </Box>
                  <CssTextField focusColor={labelFocusColor}
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Address"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.address}
                    name="address"
                    // error={!!touched.address && !!errors.address}
                    // helperText={touched.address && errors.address}
                    sx={{ gridColumn: "span 4" }}
                  />
                  <CssTextField focusColor={labelFocusColor}
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Name of Employer"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.employerName}
                    name="employerName"
                    // error={!!touched.employerName && !!errors.employerName}
                    // helperText={touched.employerName && errors.employerName}
                    sx={{ gridColumn: "span 4" }}
                  />
                </Box>
                <Box display="flex" mt="30px" mb='80px' gap="30px">
                  <Button type="submit" color="secondary" variant="contained">
                    {processing}
                  </Button>
                  <Button onClick={deleteBorrower} color="error" variant="outlined">
                    DELETE BORROWER
                  </Button>
                  <Button onClick={()=>navigate(`/manageBorrowers`)} color="error" variant="outlined">
                    CANCEL
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>}
      </Box>}
    </Box>
  );
};

const phoneRegExp = /^(?![A-Za-z]{1,9}$)[^A-Za-z]{9,}$/

const checkoutSchema = yup.object().shape({
  firstName: yup.string().required("required"),
  otherName: yup.string().required("required"),
  email: yup.string().email("invalid email"),
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Phone number is not valid")
    .required("required"),
  
});


export default ViewAndEditBorrower;